import "./main.scss";
import React from "react";
import ReactDOM from "react-dom";
import Year from "./components/Year";
import Testimonials from "./components/Testimonials";

ReactDOM.render(<Year />, document.getElementById("year-hook"));
ReactDOM.render(<Testimonials />, document.getElementById("testimonials-hook"));

// Enables Hot Module Reloading (HMR) during Development
if (module.hot) {
  module.hot.accept();
}
