import React, { useEffect, useState } from "react";

const testimonials = [
  {
    id: 1,
    author: "Dan Kay",
    testimonial:
      "I have trained with Miles for almost 3 years. I am a business owner with a background in endurance (triathlon,marathon) events. Miles motivated and inspired me to succeed as an athlete in natural bodybuilding and in life. His experience and knowledge about the human body and diet is amazing. I have competed for 2 years in natural bodybuilding and could never have achieved my masters pro card and won first place each year without Miles. He is a professional and the best!"
  },
  {
    id: 2,
    author: "Sandra Rangel, Rangel and Associates",
    testimonial:
      "I trained with Miles thru my entire pregnancy and he helped me stay strong and in good health. I believe this training helped me have a quick delivery as well. I was able to lose all the baby weight and fit back into my pre baby Jeans. I continue to train with Miles because he helps give me the results I am looking for."
  },
  {
    id: 3,
    author: "Cynthia Havelka",
    testimonial:
      "My journey began, February 2012, I eventually came to know Miles Stovall, Who I recognize as my supporter, my strength, my superhero. Miles set weekly and monthly goals; nutrition advice was also incorporated into my sessions. I learned what food choices would be efficient for nourishment, muscle building and fuel to continue with intense training sessions. Monday weigh-ins came to be an exciting event for me. Noticing my dramatic weight loss fueled my motivation. My. Average weight loss was 15 pounds a month. Every session with Miles was inspiring and motivating, his wisdom and energy is amazing. Miles truly believed in me, when I struggled to believe in myself. Through his knowledge and passion for health & fitness, along with his tough love and dedicated encouragement, I stand before you today with confidence, a winner none the less for I have exceeded my own expectations of myself by pushing beyond my medical limitations and reaching my goals. This is not only a physical transformation that you see but as well the transformation within, a new mindset. A suppressed joy has been rejuvenated and I am living!"
  },
  {
    id: 4,
    author: "James Quail",
    testimonial:
      "When I decided to train for my first bodybuilding competition I knew exactly who I wanted to be my personal trainer.  Wanting to do my best and look my best I reached out to Miles.  With his great personal training experience and accomplishments in bodybuilding this was a no brainer for me.  Not only did he help me attain my goals but he helped me change my life forever.  His training techniques and attention to detail are next to none.  Even today when I prepare for competitions you will find me training with Miles Stovall. He always helps me reach and surpass my goals!"
  },
  {
    id: 5,
    author: "Lulu Olson-SchroderKinetic Concepts, Inc",
    testimonial:
      "I am in the best shape of my life and look much younger than my age thanks to working out with Miles! Being fit and having a great body are the best confidence builders in the world…and I have both thanks to Miles Stovall!"
  },
  {
    id: 6,
    author: "Todd A. GoldNai Reoc San Antonio",
    testimonial:
      "While it is a pleasure to watch Miles grow in his artwork, it is encouraging for a 50-something male like me to see how another male approaching that milestone in life maintains his focus on his goals in life.  This is especially true when those goals include so many things such as commitment to a healthy and fit lifestyle and the continual pursuit of personal growth.  I am proud to call miles a close friend and I look forward to watching him develop as an artist in the years ahead."
  }
];

const styles = {
  textAlign: "right"
};

const Testimonials = () => {
  // console.log(testimonials);
  const [testimonial, setTestimonial] = useState(testimonials[0]);
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentNumber < testimonials.length) {
        console.log("currentNumber:", currentNumber);
        setCurrentNumber(currentNumber + 1);
        setTestimonial(testimonials[currentNumber]);
      } else {
        setCurrentNumber(0);
      }
    }, 5000);

    return () => clearTimeout(timer);
  });

  return (
    <div>
      {/* {testimonials.map(t => ( */}
      <div
        style={{ fontSize: ".8rem" }}
        className="flex animated zoomIn"
        key={testimonial.id}
      >
        <a style={{ color: "#fff" }} href="/testimonials#testimonials">
          <img
            style={{ float: "left", marginRight: 7 }}
            src="/images/home-page/footer/author.png"
            alt=""
          />
          <div>{`"${testimonial.testimonial.substr(0, 125)}..."`}</div>
        </a>
        <div style={styles}> - {testimonial.author}</div>
      </div>
      {/* ))} */}
    </div>
  );
};

export default Testimonials;
